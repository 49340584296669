import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useTheme } from '@mui/material';
import SidebarInner from '@shared/ui/sidebars/SidebarInner';
import CheckboxGroup from '@shared/ui/inputs/CheckboxGroup';
import BackLinkButton from '@shared/ui/buttons/BackLinkButton';
import Button from '@shared/ui/buttons/Button';
import { BodyLargeSemiBold, BodyLarge, BodyMediumSemiBold } from '@components/styled/Typography';
import { ContainerRow } from '@components/styled';
import useConnectExchange from '../useConnectExchange';
import { StepFormContainer, StepText, StepTitle } from '../shared';
import { WALLET_TYPE_NAMES } from './constants';
const WalletName = styled(BodyLargeSemiBold, { shouldForwardProp: propName => propName !== 'disabled' })(props => ({
    color: props.disabled ?
        props.theme.customColors.input.borderHover : props.theme.palette.text.primary,
    letterSpacing: '-0.176px',
    lineHeight: '24px',
}));
const WalletTypeComponent = styled(BodyLarge, { shouldForwardProp: propName => propName !== 'disabled' })(props => ({
    color: props.disabled ?
        props.theme.customColors.input.border : props.theme.palette.text.secondary,
    lineHeight: '24px',
}));
const WalletLabelContainer = styled(ContainerRow)(() => ({
    justifyContent: 'space-between',
}));
const WalletAmount = styled(WalletName)((props) => ({
    textAlign: 'end',
    color: props.disabled ?
        props.theme.customColors.input.borderHover : props.theme.palette.text.primary,
}));
const WalletNameContainer = styled.div((props) => ({
    display: 'flex',
    gap: props.theme.spacing_sizes.s,
    textWrap: 'nowrap',
}));
const Banner = styled(BodyMediumSemiBold)(props => ({
    borderRadius: 6,
    marginTop: props.theme.spacing_sizes.l,
    padding: props.theme.spacing_sizes.l,
    lineHeight: '20px',
    letterSpacing: '-0.084px',
    color: props.theme.palette.text.primary,
    background: '#F8F9FA',
}));
const WalletLabel = ({ walletName, walletType, amount, disabled = false, }) => (_jsxs(WalletLabelContainer, { children: [_jsxs(WalletNameContainer, { children: [_jsx(WalletName, { disabled: disabled, children: walletName }), _jsx(WalletTypeComponent, { disabled: disabled, children: WALLET_TYPE_NAMES[walletType] })] }), _jsx(WalletAmount, { disabled: disabled, children: `$ ${amount}` })] }));
const SelectWallet = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { sidebarFormValues, goToPrevStep, goToNextStep, handleClose, } = useConnectExchange();
    const { setValue, handleSubmit, watch } = useForm({
        defaultValues: sidebarFormValues,
    });
    const wallets = watch('wallets');
    const handleWalletsSelect = (selectedWallets) => {
        setValue('wallets', selectedWallets);
    };
    const onSubmit = (values) => {
        goToNextStep(values);
    };
    return (_jsx(SidebarInner, { title: (_jsx(BackLinkButton, { onClick: goToPrevStep, children: t('overview.connect_exchange.back_btn') })), padding: `${theme.spacing_sizes.xs * 6.25}px ${theme.spacing_sizes.s * 5}px`, cancelBtnProps: { onClick: handleClose }, displayCancelBtn: true, children: _jsx(StepFormContainer, { title: (_jsx(StepTitle, { children: t('overview.connect_exchange.select_wallet.title') })), onSubmit: handleSubmit(onSubmit), content: (_jsxs(_Fragment, { children: [_jsx(StepText, { stepNumber: t('overview.connect_exchange.select_wallet.step'), text: t('overview.connect_exchange.select_wallet.text') }), _jsx(CheckboxGroup, { defaultActiveCheckboxes: sidebarFormValues.wallets, options: [
                            {
                                value: 'kucher-spot',
                                label: _jsx(WalletLabel, { walletName: 'Kucher 24.01', walletType: 'SPOT', amount: 18.09 }),
                            },
                            {
                                value: 'kucher-spot-margin',
                                label: _jsx(WalletLabel, { walletName: 'Kucher 24.01', walletType: 'MARGIN', amount: 128.09 }),
                            },
                            {
                                value: 'kucher-margin',
                                label: _jsx(WalletLabel, { walletName: 'Kucher 24.01', walletType: 'FUTURE', amount: 138.09, disabled: true }),
                                disabled: true,
                            },
                            {
                                value: 'kucher-coinm',
                                label: _jsx(WalletLabel, { walletName: 'Kucher 24.01', walletType: 'FUTURE', amount: 148.09, disabled: true }),
                                disabled: true,
                            },
                        ], onChange: handleWalletsSelect, fullWidth: true }), _jsx(Banner, { children: t('overview.connect_exchange.select_wallet.banner_text') })] })), buttons: (_jsx(Button, { type: 'submit', size: 'large', disabled: wallets.length === 0, fullWidth: true, children: t('overview.connect_exchange.select_wallet.continue_btn') })), containerGap: theme.spacing_sizes.s * 10, contentJustify: 'start', contentFlex: 1 }) }));
};
export default SelectWallet;
